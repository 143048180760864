.empty-list{
    background-color: rgba(35, 00, 30, 0.9);
    border-radius: 1em;
    padding: .5em .7em;
    text-align: center;
}

.list {
    background-color: rgba(35, 00, 30, 0.9);
    border-radius: 1em;
    color: white;
    padding: .5em .7em;
    display: grid;
    grid-template-columns: repeat(10, 1fr) .1fr repeat(10,1fr);
}

.list-item{
    display: grid;
    grid-template-columns: 1fr 6ch;
    grid-column: 1/-1;
    align-items: flex-start;
    gap: .4em;
}

.song-info{
    display: grid;
    grid-template-columns: 2ch 1fr;
    gap: .4em;
}

.number{
    display: flex;
    justify-content: flex-end;
    color: #F2AF29;
}

.arrow-left{
    justify-self: flex-end;
    align-self: center;
}

.arrow-down{
    grid-column: 11;
}

.like-text{
    justify-content: space-between;
    display: flex;
}

.played{
    color: grey;
}

.current{
    color: #F2AF29;
}

.info-block{
    background-color: rgba(35, 00, 30, 0.9);
    margin: .5em 1em;
    border-radius: 1em;
    justify-content: center;
    display: flex;
}

.info-block>*{
    padding: .5em;
    white-space: pre-line;
    text-align: center;
}
